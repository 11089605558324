import { createRoot } from "react-dom/client";
import React, { StrictMode, lazy, Suspense } from "react";
import { kcContext as kcLoginThemeContext } from "./login/kcContext";

const KcLoginThemeApp = lazy(() => import("./login/KcApp"));

// @ts-ignore
createRoot(document.getElementById("root")!).render(
    <StrictMode>
        <Suspense>
            {(()=>{
                if( kcLoginThemeContext !== undefined ){
                    return <KcLoginThemeApp kcContext={kcLoginThemeContext} />;
                }
                throw new Error(
                    "This app is a keycloak theme" +
                    "It isn't meant to be deployed outside of keycloak"
                );
            })()}
        </Suspense>
    </StrictMode>
);
